<script lang="ts">
  import { Loader } from '@googlemaps/js-api-loader';
  import { Rive } from 'rive-js';
  import { onMount } from 'svelte';
  import { fade, fly, slide } from 'svelte/transition';
  import SwiperCore, { Navigation } from 'swiper/core';
  import { Swiper, SwiperSlide } from 'swiper/svelte';
  import 'swiper/swiper-bundle.css';

  SwiperCore.use([Navigation]);
  export let params;
  export let entryData = {
    keyInfo: '',
    customMessage: '',
    khLatLang: {
      lat: 0,
      lng: 0,
    },
    welcomeImages: [],
  };
  export let status = '';
  let canvas;
  let rive;
  let canvasSlide;
  let open;
  let picturesLoaded;
  let clickUnlock;
  let firstTimeLoading = true;
  let _apiUrl: string;
  const _apiVersion = '/v3';
  _apiUrl = API_DOMAIN || 'http://localhost:3001'; //Dev: web emulator loopback IP for testing

  onMount(async () => {
    const entryDataResponse = await fetch(`${_apiUrl}${_apiVersion}/fetch_entry_data/${params.uuid}`);
    const jsonResponse = await entryDataResponse.json();
    entryData = {
      keyInfo: jsonResponse.name,
      customMessage: jsonResponse.custom_message || '',
      khLatLang: {
        lat: 47.49815,
        lng: 19.0401,
      },
      welcomeImages: jsonResponse.welcome_images,
    };
    picturesLoaded = jsonResponse?.welcome_images?.length > 0;

    const loader = new Loader({
      apiKey: 'AIzaSyBRHqLigbS1rOrAPIstVPY5nbCO6fHuBuQ',
      version: 'weekly',
    });

    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById('map') as HTMLElement, {
        center: entryData.khLatLang,
        zoom: 13,
        disableDefaultUI: true,
      });

      // new google.maps.Marker({
      //   position: entryData.khLatLang,
      //   map,
      //   title: 'Hello World!',
      // });
    });

    rive = new Rive({
      src: 'assets/new_file.riv',
      canvas: canvas,
      animations: ['Call Received'],
      autoplay: true,
    });

    activateKey();
  });

  async function activateKey() {
    canvasSlide = {};
    status = 'Activating key...';
    const path = params.key_type == 'inner' ? '/open_inner_door' : '/activate_access_key';
    const response = await fetch(`${_apiUrl}${_apiVersion}${path}`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
      body: JSON.stringify({ uuid: params.uuid }),
    });
    clickUnlock = true;
    rive.stop('Call Received');
    rive.play('Call Received (slide to unlock)');
    if (200 <= response.status && response.status <= 204) {
      setTimeout(function () {
        status = 'Key activated!';
        firstTimeLoading = false;
      }, 2500);
    } else {
      status = 'Unable to activate the key';
      entryData.customMessage = 'Please contact the owner of the flat';
      firstTimeLoading = false;
    }
  }
</script>

<main>
  <div class="header">
    <img class="home-icon" src="assets/home.svg" alt="Home" />
    <div class="header-text">
      {#if entryData.keyInfo}
        <div class="ap-name">{entryData.keyInfo}</div>
      {/if}
      <!-- TODO: add if for address -->
      <div class="ap-address">Address</div>
    </div>
  </div>

  {#if picturesLoaded && !open}
    <div class="small-img-container" on:click={() => (open = true)} out:fade={{ delay: 500 }} in:fade>
      <img class="small-img" src={entryData.welcomeImages[0].url} alt="" />
      <img class="small-zoom" src="assets/zoom_in.svg" alt="" />
    </div>
  {/if}
  <div id="map" />

  {#if picturesLoaded && open}
    <div class="black-box">
      <img src="assets/close.svg" alt="close" class="close-x" on:click={() => (open = false)} />
      <Swiper autoHeight={true} navigation={true}>
        {#each entryData.welcomeImages as { url, message } (url)}
          <SwiperSlide>
            <div class="img-container">
              <img src={url} alt="" class="carousel-img" />
              <p in:fade={{ delay: 1200 }} class="carousel-text">{message}</p>
            </div>
          </SwiperSlide>
        {/each}
      </Swiper>
    </div>
  {/if}

  {#if !firstTimeLoading}
    <div class="unlock-text" transition:slide={{ duration: 1500 }}>
      <img transition:fade={{ delay: 1500 }} class="unlock-door" src="assets/door_open.svg" alt="door-icon" />
      <h2 transition:fly={{ y: -100, duration: 1500 }} class={status === 'Unable to activate the key' ? 'error' : ''}>
        {status}
      </h2>
      <h3 transition:fly={{ y: -100, duration: 1500 }} class={status === 'Unable to activate the key' ? 'error' : ''}>
        {entryData.customMessage}
      </h3>
    </div>
  {/if}

  <div class="canvas-container">
    <canvas on:click={activateKey} class="canvas" bind:this={canvas} width="1000" height="1000" />
    <img src="assets/phonein.png" alt="" class="canvas-phonein" />
    <img src="assets/background.png" alt="" class="canvas-bg" />
  </div>
</main>

<style>
  main {
    text-align: center;
    margin: 0 auto;
  }

  #map {
    height: 32vh;
    width: 100%;
  }

  h2,
  h3 {
    color: #06438e;
  }

  h2 {
    margin-top: 0;
  }

  h3 {
    margin-bottom: 0;
  }

  .error {
    color: #ff0088;
  }

  .small-img-container {
    position: absolute;
    z-index: 1;
    left: 10px;
    top: 80px;
  }

  .small-img {
    width: 10vh;
    height: 10vh;
    border: 2px;
    border-radius: 10px;
  }

  .small-zoom {
    position: relative;
    right: 30px;
  }

  .close-x {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
  }

  .unlock-text {
    background-color: white;
    height: 17vh;
    padding-top: 40px;
    position: relative;
    top: -3vh;
    border-radius: 25px 25px 0 0;
  }

  .unlock-door {
    position: absolute !important;
    margin-left: auto;
    margin-right: auto;
    top: -10vh;
    left: 0;
    right: 0;
    text-align: center;
    height: 130px;
    overflow: visible;
  }

  .header {
    background-color: white;
    padding: 12px 12px;
    display: flex;
  }

  .header-text {
    padding: 0 24px;
    flex-direction: column;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
  }

  .ap-name {
    color: #06438e;
    font-weight: bold;
  }

  .ap-address {
    color: #2097f3;
    font-weight: lighter;
  }

  .img-container {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .black-box {
    background-color: rgba(0, 0, 0, 0.4);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 2;
    height: 100vh;
    align-items: center;
    display: flex;
  }

  .carousel-text {
    color: white;
  }

  .carousel-img {
    max-width: 90%;
  }

  .canvas-container {
    display: flex;
    justify-content: center;
    background: #1d1b4c;
    border-radius: 25px 25px 0 0;
    position: relative;
    top: -6vh;
    z-index: 0;
    flex-grow: 1;
  }

  .canvas-bg {
    pointer-events: none;
    position: fixed;
    border-radius: 25px 25px 0 0;
    width: 100%;
    height: 150%;
    z-index: -2;
  }

  .canvas-phonein {
    pointer-events: none;
    position: absolute;
    height: 100%;
    z-index: -1;
    -webkit-transform: translate3d(0, 0, 0);
  }

  .canvas {
    align-items: center;
    max-width: 100vw;
    max-height: 40vh;
  }
  .home-icon {
    height: 48px;
  }
</style>
