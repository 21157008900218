<script lang="ts">
  import Router from 'svelte-spa-router';
  import Unlock from './Unlock.svelte';
  const routes = {
    '/:uuid/:key_type?': Unlock,
  };
</script>

<main>
  <Router {routes} />
</main>
